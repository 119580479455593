// - Custom variables
// - Bootstrap functions
// - Bootstrap variables
// - Bootstrap mixins
// - Custom map amends
// - Custom utilities
// - Bootstrap main import

// Import variable overrides.
@import './abstracts/variables';

// Import Bootstrap functions, vars and mixins
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Import map amends
@import './abstracts/map-amends';

// Import our utility overrides
@import '~bootstrap/scss/maps';
@import './utilities/bootstrap-api-utilities';

// Bootstrap main import
@import '~bootstrap/scss/bootstrap';

// Slick slider
@import '~slick-carousel/slick/slick';
// @import "~slick-carousel/slick/slick-theme";

// Montserrat font import
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

@import '../../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

// Preloader import
@import './preloader';

// Custom scss
// html {
//     background: $black;
// }

body {
    font-family: 'Montserrat', sans-serif;
    height: 0;

    a {
        text-decoration: none !important;
    }

    & > img {
        width: 100%;
        position: absolute;
        top: -95px;
        left: 0;
        opacity: 0;
        pointer-events: none;
    }
}

// Scrollbar
::-webkit-scrollbar {
    width: 0px;
}

// Button css variables
.btn {
    --bs-btn-hover-bg: #{$secondary};
    --bs-btn-active-bg: #{$primary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-active-color: #{$white};
    // --bs-btn-border-color: transparent;
    // --bs-btn-hover-border-color: transparent;
    // --bs-btn-active-border-color: transparent;
    // width: 100%;
    // max-width: 380px;
}

.btn-primary {
    --bs-btn-font-size: 1.4rem;
    --bs-btn-padding-x: 4.75rem;
    --bs-btn-font-weight: 600;
}

.btn-secondary {
    --bs-btn-bg: #{$primary};
    --bs-btn-font-size: 1.2rem;
    --bs-btn-padding-x: 1.5rem;
}

.btn-secondary-2 {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$secondary};
    --bs-btn-font-size: 1.2rem;
    --bs-btn-padding-x: 1.5rem;
    --bs-btn-hover-bg: #{$primary};
    --bs-btn-active-bg: #{$secondary};
    --bs-btn-active-border-color: #{$secondary};
}

.btn-light {
    --bs-btn-color: #{$primary};
    --bs-btn-padding-x: 2rem;
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-border-color: #{$secondary};
}

.popup-overlay {
    background: rgba($black, 0.65);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out 0s;

    &.active-item {
        opacity: 1;
        visibility: visible;
    }
}

#nav-toggler-mobile {
    background: #5b1423;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out 0s;

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }

    &.active-item {
        visibility: visible;
        opacity: 1;
        // transition: opacity .3s ease-out 0s;
    }

    .mobile-overlay {
        background: rgba($black, 0.45);
    }

    .nav {
        a {
            transition: all 0.1s ease-in-out;

            &:hover {
                color: $primary !important;
            }

            &:active {
                color: $secondary !important;
            }

            &.active-item {
                color: $primary !important;
                font-weight: $font-weight-semibold !important;
            }

            &[data-title='services'] {
                &:before {
                    padding-right: 24px;
                }

                & i:before {
                    font-weight: 700 !important;
                }
            }
        }

        .nav-submenu {
            transition: all 0.3s ease-out 0s;
            opacity: 0;
            visibility: hidden;
            max-height: 0;
            max-width: 100% !important;

            &.active-item {
                opacity: 1;
                visibility: visible;
                max-height: 200px;
            }

            &:hover {
                color: $primary !important;
            }

            &:active {
                color: $secondary !important;
            }
        }

        .bck-shapes {
            img {
                transform-origin: left bottom;
                transform: translate(-50%, 50%) scale(4) rotate(42deg);
                opacity: 0.065;

                &:first-of-type {
                    top: 0%;
                    left: 50%;
                }

                &:nth-of-type(2) {
                    top: 50%;
                    left: 0%;
                }

                &:nth-of-type(3) {
                    top: 25%;
                    left: 50%;
                }

                &:last-of-type {
                    top: 50%;
                    left: 50%;
                }
            }
        }
    }
}

// Header Area
.header-area {
    background: linear-gradient(rgba($black, 0.1) 0%, rgba($black, 0) 100%);
    transition: all 0.2s ease-out 0s;

    .header-overlay {
        background: $black;
        height: 0%;
        transition: all 0.2s ease-out 0s;
    }

    &.fixed-header {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;

        .header-overlay {
            height: 100%;
        }
    }

    .main-nav {
        .nav {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease-out 0s;

            &.active-item {
                visibility: visible;
                opacity: 1;
            }

            a {
                transition: all 0.1s ease-in-out;

                &:not([data-title='services']):hover {
                    color: $primary !important;
                    filter: drop-shadow(0 0 1px $primary);
                    text-shadow: 0 0 1px $black;
                }

                &:not([data-title='services']):active {
                    color: $secondary !important;
                    filter: none;
                    text-shadow: none;
                }

                &[data-title='services'] {
                    cursor: default;

                    &:before {
                        padding-right: 24px;
                    }

                    & i:before {
                        font-weight: 700 !important;
                    }
                }

                &.active-item {
                    color: $primary !important;
                    font-weight: $font-weight-semibold !important;
                    pointer-events: none;
                }
            }

            .nav-submenu {
                transform: translate(calc(-50% + 54px), 0.5rem);
                transition: all 0.3s ease-out 0s;
                opacity: 0;
                visibility: hidden;

                &.active-item {
                    opacity: 1;
                    visibility: visible;
                }

                a {
                    line-height: $line-height-sm;

                    &:hover {
                        color: $white !important;
                        filter: drop-shadow(0 0 1px $white);
                        text-shadow: 0 0 1px $black;
                    }

                    &:active {
                        filter: none;
                        text-shadow: none;
                    }

                    &.active-item {
                        color: $white !important;
                        font-weight: $font-weight-semibold !important;
                        img {
                            filter: drop-shadow(0 0 1px $white);
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            span {
                display: block;
                width: 30px;
                height: 3px;
                margin-bottom: 7px;
                border-radius: 3px;
                background-color: $white;
                transition: all 0.1s ease-out 0s;

                &:nth-of-type(3) {
                    margin-bottom: 0;
                }
            }

            &.active-item {
                span {
                    background-color: $primary;

                    &:nth-of-type(1) {
                        transform: translate(-1px, 10px) rotate(45deg);
                    }

                    &:nth-of-type(2) {
                        transform: scaleX(0);
                        background-color: $white;
                        opacity: 0;
                    }

                    &:nth-of-type(3) {
                        transform: translate(-1px, -10px) rotate(-45deg);
                    }
                }
            }
        }
    }
}

// Main banner Area
.main-banner {
    .banner-background {
        object-fit: cover;
        transform: scale(1.02);

        &::-webkit-media-controls {
            display: none !important;
        }
    }

    .banner-overlay {
        .caption {
            transform: translate(-50%, -50%);
        }
    }

    &.img-banner {
        .banner-overlay {
            background: linear-gradient(rgba($black, 0.6) 0%, rgba($black, 0) 70px);

            @media screen and (max-width: 768px) {
                background: linear-gradient(rgba($black, 0.6) 0%, rgba($black, 0) 40px, rgba($black, 0) 100%);
            }

            .caption {
                background: radial-gradient(
                    ellipse at center,
                    rgba($black, 0.5) 0%,
                    rgba($black, 0) 50%,
                    rgba($black, 0) 100%
                );
            }
        }
    }

    &.video-banner {
        .banner-overlay {
            background: linear-gradient(rgba($black, 0.9) 0%, rgba($black, 0.2) 65%, rgba($black, 0.1) 100%);
        }

        .caption {
            background: radial-gradient(ellipse at center, rgba($black, 0.9) 0%, rgba($black, 0) 65%);
        }
    }
}

// Services Area
.services-area {
    .col-md-6 {
        max-height: calc(50vh - 120px);

        @media screen and (max-width: 768px) {
            max-height: unset;
        }
    }

    .service-item {
        * {
            // cursor: default;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            .service-item-hover {
                top: 25% !important;
                background-color: rgba($primary, 0.65);
            }

            .service-item-img {
                transform: scale(1.05);
                transition: all 1.2s ease-out 0s;
            }

            h3,
            p {
                text-shadow: 0 0 10px rgba($black, 0.65);
            }
        }

        .service-item-hover {
            @media screen and (max-width: 992px) {
                top: 25% !important;
                background-color: rgba($primary, 0.65);
            }
        }
    }
}

// Technology Area
.technology-area {
    .tech-stack {
        h6 {
            @media screen and (max-width: 768px) {
                font-size: 0.85rem;
            }
        }
    }

    .nav-tabs {
        .nav-link {
            &:hover {
                box-shadow: inset 0 -4px 0 0 $primary;
                color: $primary !important;
                transition: all 0.2s ease-out 0s;
            }

            &.active {
                box-shadow: inset 0 -4px 0 0 $primary;
                color: $primary !important;
                font-weight: $font-weight-bold !important;
            }

            &:active {
                color: $secondary !important;
                box-shadow: inset 0 -4px 0 0 $secondary;
            }

            &:before {
                content: attr(data-title);
                display: block;
                height: 0;
                overflow: hidden;
                visibility: hidden;
                pointer-events: none;
                font-weight: $font-weight-bold !important;
            }
        }
    }
}

// Opinion Area
.opinion-area {
    background-image: url(../images/backgrounds/circle-lg-primary-background.png);
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 2080px;

    .slider-clients {
        .slide-image {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: var(--radius);
                height: var(--radius);
                --radius: 73px;
                --offset: -18px;
                top: var(--offset);
                left: var(--offset);
                background-image: url(../images/testimonials/quotes-primary.png);
                background-repeat: no-repeat;
            }
        }

        p {
            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: -25px;
                width: 18px;
                height: 1px;
                background: #fff;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        .slick-arrow {
            --arrow_offset: -2rem;
            top: 45%;
        }
    }

    .slider-employees {
        .slide-content {
            p {
                &:after {
                    content: '';
                    --radius: 34px;
                    --offset: -30px;
                    position: absolute;
                    top: var(--offset);
                    left: var(--offset);
                    width: var(--radius);
                    height: var(--radius);
                    background-image: url(../images/testimonials/quotes-white.png);
                    background-repeat: no-repeat;

                    @media screen and (max-width: 768px) {
                        left: 0px;
                    }
                }
            }
        }

        .slick-arrow {
            --arrow_offset: -6rem;
            top: 35%;
        }
    }

    .slick-slider {
        @keyframes slide_out {
            from {
                opacity: 1;
                transform: scale(1);
            }

            to {
                opacity: 0;
                transform: scale(0.95);
            }
        }

        opacity: 0;
        animation-name: slide_out;
        animation-delay: 0.3s;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-direction: reverse;

        .slick-slide:not([aria-hidden='false']) {
            animation-name: slide_out;
            animation-delay: 0.2s;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
        }

        .slick-list {
            overflow: visible;
        }

        .slick-arrow {
            --radius: 70px;
            width: var(--radius);
            height: var(--radius);
            position: absolute;
            transform: translateY(-50%);
            padding: 0;
            border: 2px solid $white;
            z-index: $zindex-1;
            i:before {
                font-weight: 700 !important;
            }

            &:hover {
                transform: translateY(-50%) scale(1.04);
            }

            &:active {
                transform: translateY(-50%) scale(1.02);
            }

            @media screen and (max-width: 768px) {
                --radius: 50px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @media screen and (max-width: 1200px) {
                --arrow_offset: 1rem;
            }

            &.prev-slide {
                left: var(--arrow_offset);
            }

            &.next-slide {
                right: var(--arrow_offset);
            }
        }
    }
}

// Contact Area
.contact-area,
.apply-job-area {
    .contact-form {
        form {
            input {
                transition: all 0.3s ease-out 0s;
                border-radius: 0 !important;

                &:focus {
                    box-shadow: inset 0 -1px 0 0 $primary;
                    outline: none !important;
                    text-align: left !important;

                    &::-webkit-input-placeholder {
                        opacity: 0 !important;
                    }
                }

                &:-webkit-autofill {
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: $black !important;
                }
            }

            textarea {
                transition: all 0.3s ease-out 0s;
                resize: none;

                &:focus {
                    box-shadow: inset 0 0 0 1px $primary;
                    outline: none !important;
                    text-align: left !important;

                    &::-webkit-input-placeholder {
                        opacity: 0 !important;
                    }
                }
            }
        }
    }
}

.offset-body-background,
.offset-body-background-gray {
    display: flex;
    justify-content: center;

    &:after {
        content: '';
        --radius: 50px;
        position: absolute;
        bottom: calc(var(--radius) / -2);
        height: var(--radius);
        width: var(--radius);
        background: $white;
        transform: rotate(45deg);
    }
}

.offset-body-background-gray {
    &:after {
        background: $gray;
    }
}

// Footer Area
.footer-area {
    background: linear-gradient(to bottom, rgba($secondary, 1) 0%, rgba($primary, 1) 100%);
}

.section-list {
    position: relative;
}

.section-list p:before {
    content: '– ';
    position: absolute;
    left: 0;
}

// Back to top button
#back-to-top {
    --button-offset: 1rem;
    visibility: hidden;
    opacity: 0;
    transform: translateY(200%);
    bottom: var(--button-offset);
    right: var(--button-offset);
    background: rgba($primary, 0.8);
    z-index: 999;
    transition: all 0.1s ease-out 0s;

    i:before {
        font-weight: 700 !important;
    }

    @media screen and (max-width: 768px) {
        --button-offset: 0.5rem;
    }

    &:hover {
        background: rgba($primary, 1);
    }

    &:active {
        background: $secondary;
        transition: all 0.1s ease-out 0s;
    }

    &.active-item {
        visibility: visible;
        opacity: 1;
        transform: translateY(0%);
    }
}
