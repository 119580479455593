// Custom map
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'gray': $gray,
);

$position-values: (
    0: 0,
    5: 5%,
    10: 10%,
    30: 30%,
    35: 35%,
    50: 50%,
    65: 65%,
    100: 100%,
    10vh: 10vh,
    35vh: 35vh,
    45vh: 45vh,
    65vh: 65vh,
    100vh: 100vh,
    n25vh: -25vh,
    n35vh: -35vh,
    n55vh: -55vh,
);

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: $h7-font-size,
);

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    4_5: $spacer * 2,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 5,
    8: $spacer * 6,
    8_9: $spacer * 7,
    9: $spacer * 8,
    9_10: $spacer * 10,
    9__10: $spacer * 12,
    10: $spacer * 15,
    20: $spacer * 20,
    container: calc(var(--bs-gutter-x) * 0.5),
);
