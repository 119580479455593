// Bootstrap variable

// Color variables
$primary: #e22148;
$secondary: #b41787;
$gray: #eeeeee;

// btn variables
$btn-border-radius: 0;
$btn-font-weight: 700;
$btn-padding-y: 0.7rem;

// Headings size
$h1-font-size: 3.45rem;
$h2-font-size: 2rem;
$h3-font-size: 1.8rem;
$h4-font-size: 1.45rem;
$h7-font-size: 0.75rem;

// Font weight
$headings-font-weight: 700;
$font-weight-light-normal: 400;
$font-weight-normal: 500;

// Line height
$line-height-md: 1.75rem;
$line-height-xl: 3rem;

// z-index
$zindex-1: 1;
