// Preloader
.preloader {
    /* Body Overlay */
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    height: 100%;
    width: 100%;
    /* Change Background Color */
    background: rgba($black, 0.95);
    z-index: 99999;

    .loader {
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        .mvsoft-spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 90px;
            margin-left: -45px;
            z-index: 18;
            pointer-events: none;

            .mvsoft-spinner-container {
                pointer-events: none;
                position: absolute;
                width: 100%;
                padding-bottom: 100%;
                top: 50%;
                left: 50%;
                margin-top: -50%;
                margin-left: -50%;
                -webkit-animation: mvsoft-spinner-linspin 1568.23529647ms linear infinite;
                animation: mvsoft-spinner-linspin 1568.23529647ms linear infinite;

                .mvsoft-spinner-rotator {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    -webkit-animation: mvsoft-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                    animation: mvsoft-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;

                    .mvsoft-spinner-left {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        overflow: hidden;
                        right: 50%;

                        .mvsoft-spinner-circle {
                            left: 0;
                            right: -100%;
                            border-right-color: rgba($black, 0.95);
                            -webkit-animation: mvsoft-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
                                both;
                            animation: mvsoft-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                        }
                    }

                    .mvsoft-spinner-right {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        overflow: hidden;
                        left: 50%;

                        .mvsoft-spinner-circle {
                            left: -100%;
                            right: 0;
                            border-left-color: rgba($black, 0.95);
                            -webkit-animation: mvsoft-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                            animation: mvsoft-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                        }
                    }
                }
            }
        }

        .mvsoft-spinner-circle {
            box-sizing: border-box;
            position: absolute;
            width: 200%;
            height: 100%;
            border-style: solid;
            /* Spinner Color */
            border-color: $primary $secondary rgba($black, 0.95);
            // border-color: $primary $secondary rgba($black, .95);
            border-radius: 50%;
            border-width: 8px;
        }
    }

    &.preloader-out {
        opacity: 0;
        transition: opacity 0.5s ease-out 0s;
    }
}

/* Preloader Animations */
@keyframes mvsoft-spinner-linspin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes mvsoft-spinner-easespin {
    12.5% {
        transform: rotate(135deg);
    }

    25% {
        transform: rotate(270deg);
    }

    37.5% {
        transform: rotate(405deg);
    }

    50% {
        transform: rotate(540deg);
    }

    62.5% {
        transform: rotate(675deg);
    }

    75% {
        transform: rotate(810deg);
    }

    87.5% {
        transform: rotate(945deg);
    }

    to {
        transform: rotate(1080deg);
    }
}

@keyframes mvsoft-spinner-left-spin {
    0% {
        transform: rotate(130deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    to {
        transform: rotate(130deg);
    }
}

@keyframes mvsoft-right-spin {
    0% {
        transform: rotate(-130deg);
    }

    50% {
        transform: rotate(5deg);
    }

    to {
        transform: rotate(-130deg);
    }
}
